import "./style.css";
import slides from "./img/slides.svg";
import i1 from "./img/1.png";
import i2 from "./img/2.png";
import i3 from "./img/3.png";
import i4 from "./img/4.png";
import { Flip } from "react-reveal";
export default function Slider() {
  return (
    <div className="sliderr">
      {document.body.clientWidth > 640 ? (
        <Flip cascade left>
          <div className="sliderBody">
            <img src={i1} alt="" />
            <img src={i2} alt="" />
            <img src={i3} alt="" />
            <img src={i4} alt="" />
          </div>
        </Flip>
      ) : (
        <div className="sliderBody">
          <img src={i1} alt="" />
          <img src={i2} alt="" />
          <img src={i3} alt="" />
          <img src={i4} alt="" />
        </div>
      )}
      <div className="summary">
        <div className="leftSummary">
          <h4>Summary:</h4>
          <div>
            <p>
              <span style={{ color: "#efa058" }}>Total hashrate: </span>11,82
              PH/s (1,2 PH/s online / 10,62 PH/s miners on the way)
            </p>
          </div>
          <div>
            <p>
              <span style={{ color: "#6ED375" }}>Sold: </span>0,96 PH/s
            </p>
          </div>
          <div>
            <p>
              <span style={{ color: "#4C91E5" }}>Reserve: </span>10,86 PH/s
              (0,24PH/s online)
            </p>
          </div>
        </div>
        <div className="rightSumm">
          <h4>Everminer Values Transparency:</h4>
          <p>
            Monitor our active miners, view the distribution of the total
            hashrate between customers and our reserve, and verify our
            operations' openness at the mining pool - the source of all data.
          </p>
          <a
            href="https://www.f2pool.com/mining-user/d87416827c22b5c9aadb86e10535c4e0?user_name=everminer"
            target="_blank"
          >
            Check our fleet on F2pool →
          </a>
        </div>
      </div>
      <div
        className="register"
        onClick={() =>
          window.open("https://my.everminer.io/registration", "_blank")
        }
      >
        REGISTER
      </div>
      <span className="podic">It’s simple</span>
      <img className="slidek" src={slides} alt="" />
    </div>
  );
}
