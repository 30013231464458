import "./style.css";
import slides from "./img/slides.svg";
import { Slide } from "react-reveal";
export default function Steps() {
  return (
    <div className="steps" id="steps">
      <h2>5 simple steps</h2>
      <div class="box"></div>
      <div className="flexSteps">
        <div className="stepsEm">
          <div>1</div>
          <p>
            You register in 1 click via <br /> Google Authentication
          </p>
        </div>
        <div className="stepsEm">
          <div>2</div>
          <p>
            Add your pool address <br /> to your profile
          </p>
        </div>
        <div className="stepsEm">
          <div>3</div>
          <p>
            Buy any amount <br /> of Terahashes
          </p>
        </div>
      </div>
      <div className="flexSteps">
        <div className="stepsEm">
          <div>5</div>
          <p>
            The pool makes payouts to your wallet as soon <br /> as the balance
            reaches 0.005 BTC
          </p>
        </div>
        <div className="stepsEm">
          <div>4</div>
          <p>
            We start transferring the hashrate <br /> to your pool account
          </p>
        </div>
      </div>
      <div className="weW">
        We work with one of the largest, oldest and most reliable pools -{" "}
        <b>F2pool.</b>
      </div>
      <div className="urrent">
        Currently we use the mining pool API to create an account for you, add
        your Bitcoin address to the pool, and generate a personal pool link so
        you can always monitor your hashrate and payouts.
      </div>
      <div className="flexIn">
        <Slide left>
          <p>The best investment </p>
        </Slide>
        <Slide right>
          <div>Leave a legacy for your children</div>
        </Slide>
      </div>
      <div
        className="register"
        onClick={() =>
          window.open("https://my.everminer.io/registration", "_blank")
        }
      >
        REGISTER
      </div>
      <span className="podic">And start generating Bitcoin</span>
      <img className="slidek" src={slides} alt="" />
    </div>
  );
}
