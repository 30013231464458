import "./style.css";
import strel from "./img/strel.svg";
import { useState } from "react";
export default function FAQ() {
  const [currentQuestion, setCurrentQuestion] = useState(1);
  return (
    <div className="FAQ">
      <h2>FAQ</h2>
      <div className="faqEm" onClick={() => setCurrentQuestion(1)}>
        <div className="faqEmBody">
          <p>What is Bitcoin mining and how does it work?</p>
          <img src={strel} alt="" />
        </div>
        <p
          style={
            currentQuestion == 1 && document.body.clientWidth > 640
              ? { height: "4vw" }
              : currentQuestion == 1 && document.body.clientWidth < 640
              ? { height: "44vw" }
              : { height: "0" }
          }
        >
          Bitcoin mining is the process of using computer power to secure the
          Bitcoin network and process transactions. Miners use powerful hardware
          to solve complex mathematical puzzles, and in return, they earn
          Bitcoin as a reward.
        </p>
      </div>
      <div className="faqEm" onClick={() => setCurrentQuestion(2)}>
        <div className="faqEmBody">
          <p>
            Do I need any technical knowledge to start mining with Everminer?
          </p>
          <img src={strel} alt="" />
        </div>
        <p
          style={
            currentQuestion == 2 && document.body.clientWidth > 640
              ? { height: "5.5vw" }
              : currentQuestion == 2 && document.body.clientWidth < 640
              ? { height: "67vw" }
              : { height: "0" }
          }
        >
          No, you don't need technical expertise to start. Just your Bitcoin
          wallet for payouts. Everminer simplifies the process. We handle all
          the technical aspects, using mining pool's API, so you can monitor
          your earnings through a user-friendly interface. Just signup, add your
          Bitcoin wallet address for payouts, buy any amount of THs and enjoy
          your everlasting stream of Bitcoin.
        </p>
      </div>
      <div className="faqEm" onClick={() => setCurrentQuestion(3)}>
        <div className="faqEmBody">
          <p>How is Everminer different from other mining services?</p>
          <img src={strel} alt="" />
        </div>
        <p
          style={
            currentQuestion == 3 && document.body.clientWidth > 640
              ? { height: "4vw" }
              : currentQuestion == 3 && document.body.clientWidth < 640
              ? { height: "50vw" }
              : { height: "0" }
          }
        >
          Everminer offers a unique service where you pay once for the miner and
          the hosting of your mining equipment for life, and in return, you
          receive a lifetime income stream in Bitcoin. This model eliminates
          recurring costs and maximizes your long-term returns.
        </p>
      </div>
      <div className="faqEm" onClick={() => setCurrentQuestion(4)}>
        <div className="faqEmBody">
          <p>How soon can I start mining Bitcoin?</p>
          <img src={strel} alt="" />
        </div>
        <p
          style={
            currentQuestion == 4 && document.body.clientWidth > 640
              ? { height: "4vw" }
              : currentQuestion == 4 && document.body.clientWidth < 640
              ? { height: "55vw" }
              : { height: "0" }
          }
        >
          Once you select the number of Terrahashes and complete your payment,
          your will see the mining start within minutes(!) on the mining pool.
          The payouts will be handled by the mining pool itself directly to your
          wallet. Payouts are done daily with a minimum payout threshold of
          0.005₿.
        </p>
      </div>
      <div className="faqEm" onClick={() => setCurrentQuestion(5)}>
        <div className="faqEmBody">
          <p>Is Bitcoin mining with Everminer profitable?</p>
          <img src={strel} alt="" />
        </div>
        <p
          style={
            currentQuestion == 5 && document.body.clientWidth > 640
              ? { height: "12vw" }
              : currentQuestion == 5 && document.body.clientWidth < 640
              ? { height: "140vw" }
              : { height: "0" }
          }
        >
          With Everminer, profitability is structured for the long-term. Unlike
          traditional mining models where ongoing costs for hosting, energy,
          maintenance, and market fluctuations can impact short-term
          profitability and risk, Everminer’s model involves a one-time upfront
          payment that covers all these future expenses. This approach may seem
          more expensive initially, but it eliminates the risks associated with
          variable costs and market conditions. Consequently, while it might
          take longer to cover the initial investment, you're assured of
          eventual profitability regardless of changes in mining difficulty,
          Bitcoin prices, or operational expenses. Everminer's model is designed
          to ensure that your mining operation remains profitable and
          sustainable over the long term.
        </p>
      </div>
      <div className="faqEm" onClick={() => setCurrentQuestion(6)}>
        <div className="faqEmBody">
          <p>What kind of mining hardware does Everminer use?</p>
          <img src={strel} alt="" />
        </div>
        <p
          style={
            currentQuestion == 6 && document.body.clientWidth > 640
              ? { height: "4vw" }
              : currentQuestion == 6 && document.body.clientWidth < 640
              ? { height: "50vw" }
              : { height: "0" }
          }
        >
          Everminer uses the most efficient ASIC mining hardware to date,
          ensuring optimal performance and profitability. We regularly update
          our equipment to stay at the forefront of mining technology. That's
          what makes our model sustainable in the long run.
        </p>
      </div>
      <div className="faqEm" onClick={() => setCurrentQuestion(7)}>
        <div className="faqEmBody">
          <p>Can I choose which cryptocurrency to mine?</p>
          <img src={strel} alt="" />
        </div>
        <p
          style={
            currentQuestion == 7 && document.body.clientWidth > 640
              ? { height: "12vw" }
              : currentQuestion == 7 && document.body.clientWidth < 640
              ? { height: "135vw" }
              : { height: "0" }
          }
        >
          Currently, Everminer focuses exclusively on Bitcoin mining. This
          specialization is strategic, as Bitcoin is widely regarded as the most
          stable and established cryptocurrency. It's not only the first
          cryptocurrency but also the most widely recognized and used, often
          referred to as digital gold. By concentrating on Bitcoin, we
          capitalize on its long-term value potential, market resilience, and
          widespread acceptance. While other cryptocurrencies may offer
          short-term gains, Bitcoin has demonstrated consistent growth and
          stability over the years, making it a more reliable choice for
          long-term investment. Our aim is to optimize our operations and
          provide the best possible returns on your investment by leveraging the
          enduring value of Bitcoin.
        </p>
      </div>
      <div className="faqEm" onClick={() => setCurrentQuestion(8)}>
        <div className="faqEmBody">
          <p>What security measures are in place to protect my investment?</p>
          <img src={strel} alt="" />
        </div>
        <p
          style={
            currentQuestion == 8 && document.body.clientWidth > 640
              ? { height: "8vw" }
              : currentQuestion == 8 && document.body.clientWidth < 640
              ? { height: "80vw" }
              : { height: "0" }
          }
        >
          Everminer is brought to you by Cyberian Mine, well-known
          German-Russian mining company with immense experience and an
          impeccable track record for over 6 years. The miners are spread across
          a few facilities, each of the facilities is secured with 24/7
          surveillance and guarded access. Our experience allows us to leverage
          Cyberian Mine's established security and operational protocols, giving
          you peace of mind that your investment is in safe hands.
        </p>
      </div>
    </div>
  );
}
