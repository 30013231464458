import "./style.css";
import create from "./img/creat.png";
import { Zoom } from "react-reveal";
export default function Creator() {
  return (
    <div className="creator">
      <div className="leftCreator">
        <h3>
          From the creator <br /> of Cyberian Mine
        </h3>
        <p>
          Max Matrenitsky, Founder and CEO <br /> of Cyberian Mine GmbH.
        </p>
        <Zoom>
          <div className="greenC">
            The only company in the market that <b>openly publishes</b> its
            actual hashrate performance daily.
          </div>
        </Zoom>
        <Zoom>
          <div className="blueC">
            This is not just uptime showing how much your miner could have mined
            if it never broke down, it's the actual hashrate delivered to
            customer pool accounts.
          </div>{" "}
        </Zoom>
      </div>
      <div className="rightCreator">
        <img src={create} alt="" />
        <div className="grayC">Over 400 clients from 40 countries</div>
      </div>
    </div>
  );
}
