import Creator from "../../Components/Creator";
import Center from "../../Components/DataCentr";
import FAQ from "../../Components/FAQ";
import Header from "../../Components/Header";
import Slider from "../../Components/Slider";
import Tera from "../../Components/Tera";
import Work from "../../Components/Work";
import logo from "./img/logo.svg";
import tg from "./img/tg.svg";
import inst from "./img/inst.svg";
import "./style.css";
import Steps from "../../Components/Steps";
export default function Landing() {
  return (
    <div>
      <Header></Header>
      <Tera></Tera>
      <Work></Work>
      <Slider></Slider>
      <Center></Center>
      <Steps></Steps>
      <Creator></Creator>
      <FAQ></FAQ>
      <footer>
        <div className="footerTop">
          <img src={logo} alt="" className="logo" />
          <div
            className="signUp"
            onClick={() =>
              window.open("https://my.everminer.io/registration", "_blank")
            }
          >
            Sign up
          </div>
          <div
            className="login"
            onClick={() => window.open("https://my.everminer.io/", "_blank")}
          >
            Login
          </div>
          <a href="" className="docs">
            Privacy Policy
          </a>
          <a href="" className="docs">
            Impressium
          </a>
          <a href="" className="docs">
            Us social
          </a>
          <a
            href="https://instagr.am/everminer.io"
            target="_blank"
            className="links"
          >
            <img src={inst} alt="" />
          </a>
          <a href="https://t.me/everminer" target="_blank" className="links">
            <img src={tg} alt="" />
          </a>
        </div>
        <div className="adress">
          <p>
            <span>Everminer Limited</span>7/F, MW Tower, 111 Bonham Strand,
            Sheung Wan, HONG KONG
          </p>
          <p>
            <span>Email</span>
            <a href="mailto:info@everminer.io">info@everminer.io</a>
          </p>
        </div>
      </footer>
    </div>
  );
}
