import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { routes } from "./routes";

import { Lang } from "./Lang";
import "./fonts/stylesheet.css";
import { useState } from "react";
import Landing from "./Pages/Landing";

function App() {
  const [lang, setLang] = useState("ru");
  const location = useLocation();
  return (
    <div className="App">
      <Lang.Provider value={[lang, setLang]}>
        <Routes>
          <Route path={routes.main} element={<Landing></Landing>}></Route>
        </Routes>
      </Lang.Provider>
    </div>
  );
}

export default App;
