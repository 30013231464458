import "./style.css";
import right from "./img/right.png";
import robot from "./img/rob.png";
import { Zoom } from "react-reveal";
import mobright from "./img/mobright.png";
import mobrob from "./img/mobrob.png";
export default function Work() {
  return (
    <div className="work" id="work">
      <div className="workBody">
        <div className="leftWork">
          <Zoom left cascade>
            <h2>How it works</h2>
          </Zoom>
          <p>
            We have been operating <br /> a mining pool
          </p>
          <h3>
            for <span>6 years</span>
          </h3>
          <div>
            We only sell part of the pool's <br /> capacity, thereby reserving{" "}
            <br /> future capacity
          </div>
        </div>
        <div className="rightWork">
          <img
            src={document.body.clientWidth > 640 ? right : mobright}
            alt=""
          />
        </div>
      </div>
      <div className="fleetMine">
        <div className="fleetT">
          <p>A fleet of miners</p>
          <span>
            is constantly updated with the most efficient miners on the market
          </span>
        </div>
        <img src={document.body.clientWidth > 640 ? robot : mobrob} alt="" />
      </div>
    </div>
  );
}
