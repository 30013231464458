import "./style.css";
import i1 from "./img/1.svg";
import i2 from "./img/2.svg";
import i3 from "./img/3.svg";
import i4 from "./img/4.svg";
import boch from "./img/boch.svg";
import cir from "./img/cir.svg";
import tel from "./img/tel.png";
import gal from "./img/gal.png";
import mobTel from "./img/mobtel.png";
import { Fade, Zoom } from "react-reveal";
export default function Center() {
  return (
    <div className="center">
      <div className="centerTop">
        <img src={boch} alt="" />
        <h2>
          Data {document.body.clientWidth > 650 && <br />}
          centers
        </h2>
        <Zoom right>
          <p>
            The miners are distributed across multiple secure data centers Which
            we have used for 6 years. Plans for 2024-25 include diversifying
            equipment in different jurisdictions, including the US, UAE,
            Paraguay and Norway
          </p>
        </Zoom>
      </div>
      <div className="centerCont">
        <Zoom top>
          <img src={document.body.clientWidth > 640 ? tel : mobTel} alt="" />
        </Zoom>
        <div className="rightCenter">
          <h3>
            <span>The world's first lifetime</span> bitcoin mining contract
          </h3>
          <p>
            <span>
              A proven hosting technology for miners with a lifetime guarantee
            </span>
            <img src={gal} alt="" />
          </p>
          <Zoom left cascade>
            <ul>
              <li>
                <img src={i1} alt="" />
                <p>
                  No need to worry that the miner will break down and become
                  unprofitable before paying off.
                </p>
              </li>
              <li>
                <img src={i2} alt="" />
                <p>
                  We handle all maintenance and upgrades to keep your miner
                  running at maximum efficiency.
                </p>
              </li>
              <li>
                <img src={i3} alt="" />
                <p>Withdraw funds anytime.</p>
              </li>
              <li>
                <img src={i4} alt="" />
                <p>
                  In addition to the mined coins, you still have an asset that
                  generates Bitcoin and can be sold at any time!
                </p>
              </li>
            </ul>
          </Zoom>
          <div className="button">
            <div
              className="registration"
              onClick={() =>
                window.open("https://my.everminer.io/registration", "_blank")
              }
            >
              CALCULATE RETURN
            </div>
            <p>
              Purchase Terahashes and generate income in Bitcoin FOREVER
              <img src={cir} alt="" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
