import "./style.css";
import tel from "./img/tel.png";
import strel from "./img/strel.svg";
import att from "./img/att.png";
import { Range } from "react-range";
import { useEffect, useState } from "react";
import InputRange from "react-input-range";
import { Fade } from "react-reveal";
import tel2 from "./img/tel2.png";
const nums = [0, 110];
export default function Tera() {
  const [summ, setSumm] = useState();
  let R = 6.25;
  let D = 70343519904867;
  let t = 86400;
  const [value, setValue] = useState(50);
  const [dollar, setDollar] = useState();
  function getBTC() {
    fetch("https://blockchain.info/ticker")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDollar(data.USD.buy);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    let N = (t * R * value) / ((D / 1000000000000) * Math.pow(2, 32));
    setSumm(N);
  }, [value]);
  useEffect(() => {
    getBTC();
  }, []);
  return (
    <div className="tera" id="tera">
      <div className="inp">
        <div className="flexis">
          <h2>{value} Terahash</h2>
          <input
            type="number"
            value={value}
            max={999}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter the number of Terahashes"
          />
        </div>
        <input
          type="range"
          name=""
          id="kolom"
          step={5}
          min={0}
          max={110}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="numsic">
          {nums.map((em) => {
            return (
              <div>
                <div className="linik"></div>
                <p>{em}</p>
              </div>
            );
          })}
        </div>
        <div className="inden">
          You can independently <img src={strel} alt="" /> enter the desired
          income
        </div>
        <div className="gridDay">
          <Fade top cascade>
            <div className="flexDay">
              <div className="gridEm">
                <h4 style={{ color: "#4C91E5" }}>Day</h4>
                <p>
                  <span>$</span>
                  {summ && dollar && (summ * dollar).toFixed(2)}
                </p>
                <p>
                  <span>₿</span>
                  {summ && summ.toFixed(8)}
                </p>
                <span>Enter the amount in the income field</span>
              </div>
              <div className="gridEm">
                <h4 style={{ color: "#6ED375" }}>Month</h4>
                <p>
                  <span>$</span>
                  {summ && dollar && (summ * dollar * 30).toFixed(2)}
                </p>
                <p>
                  <span>₿</span>
                  {summ && (summ * 30).toFixed(8)}
                </p>
                <span>Enter the amount in the income field</span>
              </div>
              <div className="gridEm">
                <h4 style={{ color: "#EFA058" }}>Year</h4>
                <p>
                  <span>$</span>
                  {summ && dollar && (summ * dollar * 365).toFixed(2)}
                </p>
                <p>
                  <span>₿</span>
                  {summ && (summ * 365).toFixed(8)}
                </p>
                <span>Enter the amount in the income field</span>
              </div>
            </div>
          </Fade>{" "}
          <Fade top cascade>
            <div className="flexDay">
              <div className="gridEm">
                <h4 style={{ color: "#6FD1C5" }}>2 Years</h4>
                <p>
                  <span>$</span>
                  {summ && dollar && (summ * dollar * 365 * 2).toFixed(2)}
                </p>
                <p>
                  <span>₿</span>
                  {summ && (summ * 365 * 2).toFixed(8)}
                </p>
                <span>Enter the amount in the income field</span>
              </div>
              <div className="gridEm">
                <h4 style={{ color: "#8774D4" }}>5 Years</h4>
                <p>
                  <span>$</span>
                  {summ && dollar && (summ * dollar * 365 * 5).toFixed(2)}
                </p>
                <p>
                  <span>₿</span>
                  {summ && (summ * 365 * 5).toFixed(8)}
                </p>
                <span>Enter the amount in the income field</span>
              </div>
              <div className="gridEm">
                <h4 style={{ color: "#F18383" }}>10 Years</h4>
                <p>
                  <span>$</span>
                  {summ && dollar && (summ * dollar * 365 * 10).toFixed(2)}
                </p>
                <p>
                  <span>₿</span>
                  {summ && (summ * 365 * 10).toFixed(8)}
                </p>
                <span>Enter the amount in the income field</span>
              </div>
            </div>
          </Fade>
        </div>
        <p className="att">
          <img src={att} alt="" />
          <span>
            <b> Attention!</b> These calculations are approximate, depend on the
            current state of the market, as well as the cost of bitcoin, the
            difficulty of mining, and the size of the reward. This number can
            change in either direction.
          </span>
        </p>
      </div>
      <img src={document.body.clientWidth > 640 ? tel : tel2} alt="" />
    </div>
  );
}
